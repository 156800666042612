.scroll {
    opacity: 0.3;
    background-color: #007bff;
    width: 40px;
    height: 40px;
    position: fixed;
    bottom: 10px;
    right: 10px;
    border-radius: 5px;
    border: none;
    z-index: 999 !important;
}

.scroll:hover {
    opacity: 1;
}

.arrow-up {
    color: white;
    position: absolute;
    top: 30%;
    left: 40%;
    margin-top: -9px;
    margin-left: -5px;
    font-size: 25pt;
}