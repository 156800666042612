.modal {
    font-size: 17px;
    line-height: 1.6;
    /*display: none;*/
    position: fixed;
    position: -ms-device-fixed;
    z-index: 1300;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow-x: auto;
    background-color: rgba(0, 0, 0, 0.5);
    color: rgba(0,0, 0, 1);
}

.modal-content {
    background-color: #f4f4f4;
    margin: 25vh auto 0 auto;
    width: 95%;
    min-width: 300px;
    max-width: 700px;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.17);
    border-radius: 3px;
    animation-name: modalopen;
    animation-duration: 200ms;
    overflow-x: auto;
}

@supports (-ms-ime-align:auto) {
    .modal-content {
        margin: 20% auto 0 auto;
    }
}

.closeBtn {
    color: white;
    float: right;
    font-size: 30px;
    cursor: pointer;
    margin-top: -15px;
    margin-right: -10px;
}

.closeBtn:hover,
.closeBtn:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    margin-top: -20px;
}

@keyframes modalopen {
    from { opacity: 0; }
    to { opacity: 1; }
}

.less-margin {
    margin-top: 7px;
}

.modal-header {
    background: rgba(228, 77, 38, 0.9);
    opacity: 0.9;
    padding: 10px 20px;
    color: #fff;
    font-weight: 600;
}

.modal-footer {
    text-align: center;
    padding: 10px 5px !important;
    color: black;
    background-color: #f4f4f4;
    font-weight: 400;
    cursor: pointer;
    border-top: 1px solid #ccc;
}

.modal-footer:hover,
.modal-footer:focus {
    background-color: rgba(228, 77, 38, 0.1);
}

.modal-header,
.modal-header h3,
.modal-footer h3 {
    margin: 0;
}

.modal-body {
    padding: 10px 20px;
}

.modal-body p {
    font-size: 16px;
}

.tag {
    font-weight: 600;
    display: inline-block;
    cursor: pointer;
}